import { useEffect, useState } from "react";
import { KitModel } from "../../Api/Models/product.model";
import { kitService } from "../../Api/Services/KitService";
import { RiderModel } from "../../Api/Models/rider.model";
import { useLoading } from "../../Context/LoadingProvider";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useTranslation } from "react-i18next";
import ProductWithSizes from "./Assign/ProductWithSizes";
import ProductGroup from "./Assign/ProductGroup";
import RemainingProducts from "./Assign/RemainingProducts";
import ConfrimationScreen from "./Assign/ConfirmationScreen";
import { riderService } from "../../Api/Services/RiderService";
import { LaravelValidationError } from "../../Api/request";
import { useOutletContext } from "react-router-dom";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";

interface AssignGearProps {
  rider: RiderModel;
  finnishAssign: CallableFunction;
  missingProductsKit?: KitModel | null;
  skipedAllProducts?: CallableFunction;
}

const AssignGear: React.FunctionComponent<AssignGearProps> = ({
  rider,
  finnishAssign: updateGears,
  missingProductsKit,
  skipedAllProducts,
}) => {
  const [kitToAssign, setKitToAssign] = useState<KitModel | null>(
    missingProductsKit ?? null
  );
  const { startLoading, finnishLoading } = useLoading();
  const { showInfo } = useInfoModal();
  const { t } = useTranslation();
  const { setPageTitle, fetchRider } =
    useOutletContext<RiderProfileLayoutContextInterface>();

  useEffect(() => {
    setPageTitle(null);
  }, [t, setPageTitle]);

  useEffect(() => {
    if (rider.vehicle && !missingProductsKit) {
      startLoading("get-kit-to-assign");
      kitService
        .getKitForRider(rider.vehicle, rider.riderId)
        .then((kit) => {
          setKitToAssign(kit);

          if (!kit) {
            showInfo(t("No kit available for this rider type!"));
          }
        })
        .finally(() => {
          finnishLoading("get-kit-to-assign");
        });
    }
    //eslint-disable-next-line
  }, []);

  const setKitProductVariation = (
    kitProductId: number,
    selectedVariationId: number
  ) => {
    if (!kitToAssign?.products) {
      return;
    }

    let kitToAssignProductsState = [...kitToAssign.products];
    kitToAssignProductsState.map((x) => {
      if (x.id === kitProductId) {
        x.selectedVariationId = selectedVariationId;
        x.checked = true;
        x.selected = true;
      }
      return x;
    });

    setKitToAssign({ ...kitToAssign, products: kitToAssignProductsState });
  };

  const setKitProductGroupChoice = (
    groupId: number,
    kitProductId: number,
    selectedVariationId: number | null
  ) => {
    if (!kitToAssign?.products) {
      return;
    }

    let kitToAssignProductsState = [...kitToAssign.products];
    kitToAssignProductsState.map((x) => {
      if (x?.optionalGroupId === groupId) {
        x.checked = true;
        x.selected = false;
      }

      if (x.id === kitProductId) {
        x.selected = true;
        if (selectedVariationId) {
          x.selectedVariationId = selectedVariationId;
        }
      }
      return x;
    });

    setKitToAssign({ ...kitToAssign, products: kitToAssignProductsState });
  };

  const setItemChecked = (productId: number) => {
    if (!kitToAssign?.products) {
      return;
    }
    let kitToAssignProductsState = [...kitToAssign.products];
    kitToAssignProductsState.map((x) => {
      if (x.id === productId) {
        x.checked = true;
        x.selected = true;
      }
      return x;
    });

    setKitToAssign({ ...kitToAssign, products: kitToAssignProductsState });
  };

  const setItemPrepared = (productId: number) => {
    if (!kitToAssign?.products) {
      return;
    }
    let kitToAssignProductsState = [...kitToAssign.products];
    kitToAssignProductsState.map((x) => {
      if (x.id === productId) {
        x.prepared = true;
      }
      return x;
    });

    setKitToAssign({ ...kitToAssign, products: kitToAssignProductsState });
  };

  const uncheckProduct = (productId: number) => {
    if (!kitToAssign?.products) {
      return;
    }
    let kitToAssignProductsState = [...kitToAssign.products];

    let product = kitToAssignProductsState.find((x) => x.id === productId);

    if (!product) {
      return;
    }

    let groupId = product.optionalGroupId ?? -1;

    kitToAssignProductsState.map((x) => {
      if (
        x.id === productId ||
        (x.optionalGroupId && x.optionalGroupId === groupId)
      ) {
        x.checked = false;
        x.selectedVariationId = null;
        x.selected = false;
        x.skipped = false;
      }
      return x;
    });

    setKitToAssign({ ...kitToAssign, products: kitToAssignProductsState });
  };

  const skipProduct = (productId: number) => {
    if (!kitToAssign?.products) {
      return;
    }
    let kitToAssignProductsState = [...kitToAssign.products];

    let product = kitToAssignProductsState.find((x) => x.id === productId);

    if (!product) {
      return;
    }

    let groupId = product.optionalGroupId ?? -1;

    kitToAssignProductsState.map((x) => {
      if (
        x.id === productId ||
        (x.optionalGroupId && x.optionalGroupId === groupId)
      ) {
        x.checked = true;
        x.selectedVariationId = null;
        x.skipped = true;
        x.selected = false;
      }
      return x;
    });

    if (
      !kitToAssignProductsState.some((x) => !x.skipped && x.stockEnough) &&
      skipedAllProducts
    ) {
      kitToAssignProductsState.map((x) => {
        x.checked = false;
        x.selectedVariationId = null;
        x.skipped = false;
        x.selected = false;

        return x;
      });
      skipedAllProducts();
    }

    setKitToAssign({ ...kitToAssign, products: kitToAssignProductsState });
  };

  const skipRemainingProducts = () => {
    if (!kitToAssign?.products) {
      return;
    }
    let kitToAssignProductsState = [...kitToAssign.products];

    const remainingProducts = kitToAssign.products
      .filter(
        (x) => !(x.variations.length > 0 || x.optionalGroupId) && !x.checked
      )
      .map((x) => x.id);

    kitToAssignProductsState.map((x) => {
      if (remainingProducts.includes(x.id)) {
        x.checked = true;
        x.selectedVariationId = null;
        x.skipped = true;
        x.selected = false;
      }
      return x;
    });

    setKitToAssign({ ...kitToAssign, products: kitToAssignProductsState });
  };

  const back = () => {
    if (!kitToAssign) {
      return;
    }

    let productsState = [...kitToAssign.products.reverse()];
    console.log("asd", productsState);

    let backed = false;
    let groupId: number | null = null;

    productsState = productsState.map((x) => {
      if (
        (x.checked && !backed) ||
        (x.optionalGroupId && x.optionalGroupId === groupId)
      ) {
        backed = true;
        if (x.optionalGroupId) {
          groupId = x.optionalGroupId;
        }
        return {
          ...x,
          checked: false,
          selected: false,
          skipped: false,
          selectedVariationId: null,
        };
      }
      return x;
    });

    setKitToAssign({ ...kitToAssign, products: productsState.reverse() });
  };

  const skipAll = () => {
    if (!kitToAssign) {
      return;
    }

    let productsState = [...kitToAssign.products];

    productsState = productsState.map((x) => {
      x.skipped = true;
      x.checked = true;
      x.selected = false;
      x.selectedVariationId = null;
      return x;
    });

    setKitToAssign({ ...kitToAssign, products: productsState });
  };

  const assignKit = (images: File[]) => {
    if (!kitToAssign) {
      return;
    }
    startLoading("assign-kit");

    const productsToAssign = kitToAssign.products.filter((x) => x.prepared);

    riderService
      .assignKit(rider.riderId, kitToAssign.id, productsToAssign, images)
      .then(() => {
        updateGears();
        fetchRider();
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("assign-kit");
      });
  };

  if (!kitToAssign || !kitToAssign.products) {
    return null;
  }

  let notCheckedProducts = kitToAssign.products.filter(
    (x) => !x.checked && x.stockEnough && !x.skipped
  );
  if (notCheckedProducts.length === 0) {
    return (
      <ConfrimationScreen
        kitProducts={kitToAssign.products}
        uncheckProduct={uncheckProduct}
        setItemPrepared={setItemPrepared}
        assignKit={assignKit}
        back={back}
      />
    );
  }

  let variationalProducts = notCheckedProducts.filter(
    (x) => x.variations.length > 0 && !x.optionalGroupId
  );
  if (variationalProducts.length > 0) {
    return (
      <ProductWithSizes
        kitProduct={variationalProducts[0]}
        setKitProductVariation={setKitProductVariation}
        skipProduct={skipProduct}
        skipAll={skipAll}
        back={kitToAssign.products.some((x) => x.checked) ? back : null}
      />
    );
  }

  let groupedProducts = notCheckedProducts.filter((x) => x.optionalGroupId);
  if (groupedProducts.length > 0) {
    return (
      <ProductGroup
        kitProducts={groupedProducts.filter(
          (x) => x.optionalGroupId === groupedProducts[0].optionalGroupId
        )}
        setKitProductGroupChoice={setKitProductGroupChoice}
        skipProduct={skipProduct}
        skipAll={skipAll}
        back={kitToAssign.products.some((x) => x.checked) ? back : null}
      />
    );
  }

  let remainingProducts = kitToAssign.products.filter(
    (x) => !(x.variations.length > 0 || x.optionalGroupId)
  );
  return (
    <RemainingProducts
      kitProducts={remainingProducts}
      setItemChecked={setItemChecked}
      skipRemainingProducts={skipRemainingProducts}
      back={back}
    />
  );
};

export default AssignGear;
