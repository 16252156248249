import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { StockModel } from "../../Api/Models/stock.model";
import { stockService } from "../../Api/Services/StockService";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";
import { weeklyStockCheckService } from "../../Api/Services/WeeklyStockCheckService";

interface WeeklyStockCheckProps {}

const WeeklyStockCheck: React.FunctionComponent<WeeklyStockCheckProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [stocks, setStocks] = useState<StockModel[]>([]);
  const { startLoading, finnishLoading } = useLoading();
  const [stockCorrectionProductId, setStockCorrectionProductId] = useState<
    number | null
  >(null);
  const { showInfo } = useInfoModal();

  const stockCorrectionTextRef = useRef<HTMLTextAreaElement | null>(null);
  const stockCorrectionNumberRef = useRef<HTMLInputElement | null>(null);
  const [selectedProductVariation, setSelectedProductVariation] = useState<
    number | null
  >(null);

  const [createdStockCorrections, setCreatedStockCorrections] = useState<
    { productId: number; quantity: string; variationId: number | null }[]
  >([]);

  const [checkedStocks, setCheckedStocks] = useState<number[]>([]);
  const [savedChckedStocks, setSavedChckedStocks] = useState<number[]>([]);

  useEffect(() => {
    loadStocks();
    loadCheckedItems();
    //eslint-disable-next-line
  }, []);

  const loadStocks = () => {
    startLoading("get-stock-list");
    stockService
      .listStocks()
      .then((stocks) => {
        setStocks(stocks);
      })
      .finally(() => {
        finnishLoading("get-stock-list");
      });
  };

  const loadCheckedItems = () => {
    startLoading("get-checked-products");
    weeklyStockCheckService
      .thisWeekCheckedProducts()
      .then((products) => {
        setSavedChckedStocks(products.productIds);
      })
      .finally(() => {
        finnishLoading("get-checked-products");
      });
  };

  const sendStockCorrectionRequest = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      stockCorrectionProductId &&
      stockCorrectionTextRef.current &&
      stockCorrectionNumberRef.current
    ) {
      startLoading("send-stock-correction");

      stockService
        .stockCorrectionRequest(
          stockCorrectionProductId,
          selectedProductVariation,
          +stockCorrectionNumberRef.current.value,
          stockCorrectionTextRef.current?.value
        )
        .then((stocks) => {
          setStockCorrectionProductId(null);
          if (
            stockCorrectionTextRef.current &&
            stockCorrectionNumberRef.current
          ) {
            setCreatedStockCorrections([
              ...createdStockCorrections,
              {
                productId: stockCorrectionProductId,
                variationId: selectedProductVariation,
                quantity: stockCorrectionNumberRef.current?.value,
              },
            ]);

            stockCorrectionTextRef.current.value = "";
            stockCorrectionNumberRef.current.value = "";
          }

          if (!selectedProductVariation) {
            setCheckedStocks([...checkedStocks, stockCorrectionProductId]);
          }

          setSelectedProductVariation(null);
          showInfo(t("Stock correction request sent!"));
        })
        .finally(() => {
          finnishLoading("send-stock-correction");
        });
    }
  };

  const uniqueProductStocks = (): StockModel[] => {
    let uniqueProductStocks: StockModel[] = [];
    stocks.forEach((stock) => {
      if (!uniqueProductStocks.some((x) => x.productId === stock.productId)) {
        uniqueProductStocks.push(stock);
      }
    });

    return uniqueProductStocks;
  };

  const saveWeeklyStockCheck = () => {
    startLoading("save-weekly-stock-check");

    weeklyStockCheckService
      .store(checkedStocks)
      .then(() => {
        showInfo(t("Weekly stock check successfully saved!"));
        navigate("/menu");
      })
      .catch(() => {
        showInfo(t("There was an error while saveing weekly stock check-in!"));
      })
      .finally(() => {
        finnishLoading("save-weekly-stock-check");
      });
  };

  return (
    <div className="view-container background-3">
      <div
        className={"modal" + (!stockCorrectionProductId ? " hidden" : "")}
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal__dialog modal-dialog--full-height">
          <div className="modal__header">
            <div className="modal__heading">
              {t("Stock correction request")}
            </div>
            <div
              className="modal__close"
              onClick={() => {
                setStockCorrectionProductId(null);
                if (stockCorrectionTextRef.current) {
                  stockCorrectionTextRef.current.value = "";
                }
              }}
            >
              ✖
            </div>
          </div>
          <form className="modal__body" onSubmit={sendStockCorrectionRequest}>
            {stocks.filter((x) => x.productId === stockCorrectionProductId)
              .length > 1 ? (
              <>
                <label>{t("Size")}</label>
                <select
                  className="input-field bg-lightgray mb-2"
                  onChange={(e) => setSelectedProductVariation(+e.target.value)}
                  required
                >
                  <option value="">-</option>
                  {stocks
                    .filter((x) => x.productId === stockCorrectionProductId)
                    .map((stock) => (
                      <option
                        key={stock.variationId}
                        value={stock.variationId ?? ""}
                      >
                        {stock.variationName}
                      </option>
                    ))}
                </select>
              </>
            ) : null}
            <label>{t("Updated quantity")}</label>
            <input
              type="number"
              step={1}
              min={0}
              max={9999999}
              ref={stockCorrectionNumberRef}
              placeholder={t("Updated quantity") ?? ""}
              className="input-field bg-lightgray mb-2"
              required
            />
            <label>{t("Message")}</label>
            <textarea
              ref={stockCorrectionTextRef}
              placeholder={t("Message") ?? ""}
              className="bg-lightgray mb-6"
            ></textarea>
            <div className="flex justify-center mb-8 mt-5">
              <button type="submit" className="button button--blue">
                {t("Send")}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="view-content">
        <div className="heading text-center">{t("Weekly stock check-in")}</div>

        <div className="">
          {/*<div className="flex justify-center mb-8">
              <div className="select-rounded-wrapper">
                <select>
                  <option value="Warehouse # 1">Warehouse # 1</option>
                  <option value="Warehouse # 2">Warehouse # 2</option>
                </select>
              </div>
          </div>*/}
          <div className="card-list mb-12">
            {uniqueProductStocks()
              .filter((x) => !savedChckedStocks.includes(x.productId))
              .map((stock, index) => (
                <div className="stock-list-card" key={index}>
                  <div className="stock-list-card__image">
                    {stock.productImage && (
                      <img
                        src={stock.productImage}
                        height="50px"
                        alt="Product"
                      />
                    )}
                  </div>
                  <div className="stock-list-card__content">
                    <div
                      className="stock-list-card__title"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {stock.productName}
                    </div>
                    {stock.variationId ? (
                      <div className="stock-list-card__info">
                        {stocks
                          .filter((x) => x.productId === stock.productId)
                          .map((stockVariation) => (
                            <div key={stockVariation.variationId}>
                              <strong>{stockVariation.variationName}</strong>
                              &nbsp;
                              {stockVariation.quantity +
                                stockVariation.sanitizedQuantity}
                              pcs
                              {createdStockCorrections.find(
                                (x) =>
                                  x.productId === stock.productId &&
                                  x.variationId === stockVariation.variationId
                              ) ? (
                                <span className="text-red">
                                  &nbsp;(
                                  {
                                    createdStockCorrections.find(
                                      (x) =>
                                        x.productId === stock.productId &&
                                        x.variationId ===
                                          stockVariation.variationId
                                    )?.quantity
                                  }
                                  &nbsp;pcs)
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                      </div>
                    ) : createdStockCorrections.find(
                        (x) =>
                          x.productId === stock.productId &&
                          x.variationId === null
                      ) ? (
                      <span className="text-red">
                        &nbsp;(
                        {
                          createdStockCorrections.find(
                            (x) => x.productId === stock.productId
                          )?.quantity
                        }
                        &nbsp;pcs)
                      </span>
                    ) : null}
                  </div>
                  <div className="stock-list-card__right">
                    <div className="stock-list-card__pc">
                      {stock.variationId
                        ? stocks
                            .filter((x) => x.productId === stock.productId)
                            .reduce((accumulator, object) => {
                              return (
                                accumulator +
                                object.quantity +
                                object.sanitizedQuantity
                              );
                            }, 0)
                        : stock.quantity + stock.sanitizedQuantity}{" "}
                      {t("pc")}
                    </div>
                    <button
                      type="button"
                      className={
                        checkedStocks.includes(stock.productId)
                          ? "button-product-status-returned"
                          : "button-product-status-default"
                      }
                      onClick={() => {
                        if (checkedStocks.includes(stock.productId)) {
                          setCheckedStocks([
                            ...checkedStocks.filter(
                              (x) => x !== stock.productId
                            ),
                          ]);
                        } else {
                          setCheckedStocks([...checkedStocks, stock.productId]);
                        }
                      }}
                    >
                      <i className="icon-approve"></i>
                    </button>
                    <button
                      type="button"
                      className="button-product-status-missed"
                      onClick={() => {
                        setStockCorrectionProductId(stock.productId);
                      }}
                    >
                      <i className="icon-edit"></i>
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <div className="flex justify-center">
            <button
              type="button"
              className="button button--green"
              onClick={() => saveWeeklyStockCheck()}
            >
              {t("Save")}
            </button>
          </div>

          <div className="flex justify-center mt-6">
            <Link to="/menu" className="button">
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeeklyStockCheck;
